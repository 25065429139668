export default {
    area: "定鼎门社区",
    objectName: "",
    //url
    hostUrl : 'https://kylddmapp.zhsq.cloud/',
    apiHttpsUrl : 'https://kylddmapi.zhsq.cloud/',
    apiRequestUrl : 'https://kylddmapi.zhsq.cloud/api',
    apiUploadUrl: 'https://kylddmapi.zhsq.cloud/api/UploadFile/UploadImgs',
    apiUploadFiles:'https://kylddmapi.zhsq.cloud/api/UploadFile/UploadFiles',
    apiUploadvideo:'https://kylddmapi.zhsq.cloud/api/UploadFile/UploadQiniuVideo',
}